<template>
    <div
        class="custom-modal keywords-table-by-ranking-url"
        data-cy="keywords-table-by-ranking-url"
    >
        <div class="custom-modal__header">
            <div class="main-header">{{ 'Ranking Keywords' }}</div>
            <div class="second-header">
                {{ 'URL: ' }}
                <span class="like-link" @click="openPage">{{ rankingUrl }}</span>
            </div>
            <closer @click="close" />
        </div>

        <div class="custom-modal__body">
            <project-keywords-and-charts />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectKeywordsAndCharts from '@/components/main-block/components/ProjectKeywordsAndCharts';
export default {
    name: 'KeywordsByRankingUrl',
    components: {
        'project-keywords-and-charts': ProjectKeywordsAndCharts,
    },
    props: {
        rankingUrl: {
            type: String,
            required: true,
        },
        selectedKeyword: {
            type: String | null,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            init: false,
            sortChanges: false,
            oldDisplayLength: null,
            oldSortFIeld: null,
            oldSortDirection: null,
        };
    },
    watch: {
        getOriginalItems: {
            deep: true,
            handler: 'setSelectedKeyword',
        },
        getDisplaySettings: {
            deep: true,
            handler: 'setIfSortChanges',
        },
    },
    computed: {
        ...mapGetters([
            'getOriginalItems',
            'getDisplayLength',
            'getOtherSettings',
            'getDisplaySetting',
            'getDisplaySettings',
            'getSelectedKeywordsWithAllData',
        ]),
    },
    created() {
        this.oldDisplayLength = this.getDisplayLength;
        this.setOtherSettings({...this.getOtherSettings, display_length: 10});

        if (this.selectedKeyword) {
            this.oldSortFIeld = this.getDisplaySetting('sort_by');
            this.oldSortDirection = this.getDisplaySetting('sort_by_order');
        }

        this.resetAllParams(1, this.rankingUrl);
    },
    methods: {
        ...mapActions([
            'setKeywords',
            'setOtherSettings',
            'setTotalKeywords',
            'setSelectedKeywords',
            'setFilterByRankingUrl',
            'setTotalKeywordsPages',
            'setKeywordsTableSorter',
            'setAllCheckboxesSelected',
        ]),
        resetAllParams(source, rankingUrl = false) {
            if (this.selectedKeyword && !this.sortChanges) {
                let sort = {
                    sort_by: 'g',
                    sort_by_order: 'asc',
                };

                if (source === 2) {
                    sort = {
                        sort_by: this.oldSortFIeld,
                        sort_by_order: this.oldSortDirection,
                    };

                }

                this.setKeywordsTableSorter(sort);
            }
            let display_length = 10;

            if (source === 2) {
                display_length = this.oldDisplayLength;
            }

            this.setOtherSettings({...this.getOtherSettings, display_length});
            this.setFilterByRankingUrl(rankingUrl);
            this.setKeywords();
            this.setTotalKeywords();
            this.setSelectedKeywords([]);
            this.setTotalKeywordsPages();
            this.setAllCheckboxesSelected(false);
        },
        setSelectedKeyword(originalItems) {
            if (!this.init && originalItems.length && this.selectedKeyword) {
                let id = originalItems.find(
                    keyword => keyword.kw === this.selectedKeyword,
                )?.id;

                if (id) {
                    this.setSelectedKeywords([id]);
                }

                this.init = true;
            }
        },
        setIfSortChanges(to) {
            if (to.sort_by != 'g' || to.sort_by_order != 'asc') {
                this.sortChanges = true;
            }
        },
        openPage() {
            window.open(`${this.rankingUrl}`, '_blank');
        },
        close() {
            this.resetAllParams(2);
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.keywords-table-by-ranking-url {
    padding: 12px 0 15px 0;
    max-height: 90vh;

    .custom-modal__header {
        padding: 0 15px 0 14px;
        color: #000;
        font-family: Roboto;
        font-style: normal;
        line-height: normal;

        .main-header {
            font-size: 14px;
            font-weight: 700;
        }

        .second-header {
            font-size: 13px;
            font-weight: 500;
            margin-top: 6px;

            .like-link {
                text-decoration-line: underline;
            }
        }

        /deep/ .button-closer {
            font-size: 10px;
        }
    }

    .custom-modal__body {
        border: none;
        width: 100%;
        max-height: calc(80vh - 53px);
        min-height: 50vh;
        padding: 0 15px 0 14px;
        overflow-y: auto;

        .keywords-container {
            width: 100%;
        }

        .keywords-table-wrap {
            height: 100%;
            overflow-y: auto;
        }

        /deep/ .table-pagination {
            flex: 1 1 100%;
        }
    }
}
</style>
